import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseController } from '../../base.controller';
import { SecurityController } from '../../security/security.controller';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { RouteCMRDto } from '@shared/src/dtos/route/cmr/RouteCMRDto';
import { RoutePartCMRDto } from '@shared/src/dtos/route/cmr/RoutePartCMRDto';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { RoutePartCMRAdditionalDataDto } from '@shared/src/dtos/route/cmr/RoutePartCMRAdditionalDataDto';
import { RoutePartCMREventDto } from '@shared/src/dtos/route/cmr/RoutePartCMREventDto';
import { Route_eCMRPartnerDTO } from '@shared/src/dtos/route/cmr/ecmrparts/Route_eCMRPartnerDto';
import { Route_eCMRIncotermDto } from '@shared/src/dtos/route/cmr/ecmrparts/Route_eCMRIncotermDto';
import { Route_eCMRAddressDTO } from '@shared/src/dtos/route/cmr/ecmrparts/Route_eCMRAddressDto';
import { Route_eCMRPaymentDto } from '@shared/src/dtos/route/cmr/ecmrparts/Route_eCMRPaymentDto';
import { RoutePartCMRSignatureAgents } from './RoutePartCMRSignatureAgents';
import { RoutePartCMRSignListDto } from './RoutePartCMRSignListDto';
import { UUIDSignatureDto } from './UUIDSignatureDto';


@Injectable({
    providedIn: 'root',
})
export class RouteCMRController extends BaseController {

    private base = '/routecmr'

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router
    ) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getRouteCMR(routeId: number): Observable<RouteCMRDto> {
        return this.get(this.base + "/" + routeId);
    }

    public getRoutePartCMR(routePartCMRId: number): Observable<RoutePartCMRDto> {
        return this.get(this.base + "/routepartcmr/" + routePartCMRId);
    }

    public recalculate(routeId: number): Observable<RouteCMRDto> {
        return this.get(this.base + "/" + routeId + "/recalculate");
    }

    public saveRoutePartCMR(value: RoutePartCMRDto): Observable<RoutePartCMRDto> {
        return this.post(this.base + "/routepartcmr/save", value, "saveRoutePartCMR");
    }

    public getRoutePartCMRAdditionalData(routePartCMRId: number): Observable<RoutePartCMRAdditionalDataDto> {
        return this.get(this.base + "/routepartcmr/" + routePartCMRId + "/additionaldata");
    }
    public saveRoutePartCMRAdditionalData(value: RoutePartCMRAdditionalDataDto): Observable<RoutePartCMRAdditionalDataDto> {
        return this.post(this.base + "/routepartcmr/additionaldata/save", value, "saveRoutePartCMRAdditionalData");
    }

    public getRoutePartCMREvents(routePartCMRId: number): Observable<RoutePartCMREventDto[]> {
        return this.get(this.base + "/routepartcmr/" + routePartCMRId + "/events");
    }
    public saveRoutePartCMREvent(value: RoutePartCMREventDto): Observable<boolean> {
        return this.post(this.base + "/routepartcmr/events/save", value, "saveRoutePartCMREvent");
    }

    public updateOriginTenant(routePartCMRId: number, data: Route_eCMRPartnerDTO): Observable<Boolean> {
        return this.put(this.base + "/" + routePartCMRId + "/updateorigintenant", data, "updateOriginTenant");
    }

    public updateDestinationTenant(routePartCMRId: number, data: Route_eCMRPartnerDTO): Observable<Boolean> {
        return this.put(this.base + "/" + routePartCMRId + "/updatedestinationtenant", data, "updateDestinationTenant");
    }

    public updateCarrier(routePartCMRId: number, data: Route_eCMRPartnerDTO): Observable<Boolean> {
        return this.put(this.base + "/" + routePartCMRId + "/updatecarrier", data, "updateCarrier");
    }

    public updateDriver(routePartCMRId: number, data: Route_eCMRPartnerDTO): Observable<Boolean> {
        return this.put(this.base + "/" + routePartCMRId + "/updatedriver", data, "updateDriver");
    }

    public updateApplicant(routePartCMRId: number, data: Route_eCMRPartnerDTO): Observable<Boolean> {
        return this.put(this.base + "/" + routePartCMRId + "/updateapplicant", data, "updateApplicant");
    }

    public updatePayer(routePartCMRId: number, data: Route_eCMRPartnerDTO): Observable<Boolean> {
        return this.put(this.base + "/" + routePartCMRId + "/updatepayer", data, "updatePayer");
    }

    public updateIncoterm(routePartCMRId: number, data: Route_eCMRIncotermDto): Observable<Boolean> {
        return this.put(this.base + "/" + routePartCMRId + "/updateincoterm", data, "updateIncoterm");
    }

    public updatePayment(routePartCMRId: number, data: Route_eCMRPaymentDto): Observable<Boolean> {
        return this.put(this.base + "/" + routePartCMRId + "/updatepayment", data, "updatePayment");
    }

    public updateOriginAddress(routePartCMRId: number, data: Route_eCMRAddressDTO): Observable<Boolean> {
        return this.put(this.base + "/" + routePartCMRId + "/updateoriginaddress", data, "updateOriginAddress");
    }

    public updateDestinationAddress(routePartCMRId: number, data: Route_eCMRAddressDTO): Observable<Boolean> {
        return this.put(this.base + "/" + routePartCMRId + "/updatedestinationaddress", data, "updateDestinationAddress");
    }

    getHistory(routePartCMRId: number, type: RoutePartCMRSignatureAgents): Observable<RoutePartCMRSignListDto[]> {
        return this.get(this.base + "/routepartcmr/" + routePartCMRId + "/sign/" + type + "/history");
    }


    executeRoutePartCMRActions(action: ActionDto, routePartCMR: RoutePartCMRDto): Observable<any> {
        if (routePartCMR != null)
            if (action != null) {

            }

        return new Observable<any>((observer) => {
            observer.next(false);
            observer.complete();
        });
    }


    public getSignByAgentAndUUID(agent: any, uuid: string): Observable<UUIDSignatureDto> {
        return this.get(this.base + "/sign/agent/" + agent + "/uuid/" + uuid);
    }
}
