<div *ngIf="value" class="container" [ngClass]="{'edit' : value.routeGroup != null && value.routeGroup.isGrouped}"
    (dragover)="dragover($event)" (drop)="dropAtZone($event)">

    <div class="header">
        <div class="line">
            <div *ngIf="value.routeGroup != null" class="key"> {{ value.routeGroup.routeKey}}</div>
            <div *ngIf="value.routeGroup == null" class="subtitle">{{ 'ROUTEGROUP.CREATEROUTEGROUP.TITLE' | translate}}
            </div>
        </div>

        <div class="help-text" [ngClass]="{'help' : getCountActualRoutes() > 0 }">
            {{'ROUTEGROUP.DRAGANDDROP.CARDITEM.PLACEHOLDER' | translate }}</div>
    </div>

    <div class="block" *ngIf="getCountActualRoutes() > 0">
        <div class="line">
            <input class="searchbox" placeholder="{{'SEARCH'| translate}}" type=text [(ngModel)]="searchText">
        </div>
        <div class="subtitle">{{ 'ROUTEGROUP.ACTUALROUTECHILDS.TITLE' | translate}}</div>
        <div class="line llista">
            <div class="overflow">
                <div class="grouped-route-item-panel"
                    *ngFor="let item of getActualRoutes() | filterActiveRoutes:searchText:filterMetadata | orderbyroutecarddto:true; let i=index"
                    [ngClass]="{'divided-childs': item.isDivided && item.childsCount > 0}">
        
                    <div class="info">
                        <div class="key">{{item.title}}</div>
                        <div class="key" *ngIf="item.title != item.subtitle">{{item.subtitle}}</div>
                        <div class="description">{{item.line1}}</div>
                    </div>
                    <i *ngIf="!preFinishActive && getCountActualRoutes() > 0 && item.routeId != routeId"
                        class="tancar fa fa-times-circle" aria-hidden="true" (click)="onRouteRemove(item)"></i>
                </div>
                <div class="dummy"
                    [ngClass]="{'textwarning': preFinishActive && getCountActualRoutes() == 0 && value != null && value.routeGroup != null}">
                    .</div>
            </div>
        </div>

    </div>

    <div class="button-zone"
        [ngClass]="{'textwarning': preFinishActive && getCountActualRoutes() == 0 && value != null && value.routeGroup != null}">
        <div class="warning-zone">
            <span class="warning"
                *ngIf="preFinishActive && getCountActualRoutes() == 0 && value != null && value.routeGroup != null">{{
                'ROUTEGROUP.WARNING.CANCELED' | translate }}</span>
        </div>
        <div class="block-buttons" *ngIf="preFinishActive || !(iReadonly || !canOnRouteGrouped())">
            <button *ngIf="!(iReadonly || !canOnRouteGrouped())" class="add" (click)="onRouteGrouped()">{{
                getActionButtonToGroupResource() | translate}}</button>
            <button *ngIf="preFinishActive" class="cancel" [disabled]="iReadonly" (click)="onCancelRouteGrouped()">{{
                'ROUTEGROUP.OPTION.ROUTEGROUP.CANCEL' | translate}}</button>
        </div>
    </div>
</div>