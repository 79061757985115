import { EventEmitter, Inject, Injectable } from '@angular/core';
import { SocketNotificationDto } from "./SocketNotificationDto";
import { ws } from '../../environments/environment';
import { SecurityController } from '../security/security.controller';
import { SocketNotificationAction } from './SocketNotificationAction';
import { LogController } from '../log/LogController';
import { AlertService } from '../../services/alert/alert.service';
import { HDateHour } from '@shared/src/datatypes/HDateHour';
import { HLong } from '@shared/src/datatypes/HLong';
import { userDto } from '@shared/src/dtos/user/userDto';

@Injectable({
    providedIn: 'root',
})
export class SocketController {

    public notificationReloadHome$: EventEmitter<SocketNotificationDto> = new EventEmitter();
    public notificationReloadHomeNext: HDateHour;

    public notificationDatasetTimeline$: EventEmitter<SocketNotificationDto> = new EventEmitter();
    public notificationReloadMenu$: EventEmitter<SocketNotificationDto> = new EventEmitter();
    public notificationReloadLite$: EventEmitter<SocketNotificationDto> = new EventEmitter();
    public notificationInterestedOn$: EventEmitter<String> = new EventEmitter();

    public notificationReceived$: EventEmitter<SocketNotificationDto> = new EventEmitter();

    /** Estat del socket */
    private status: number;

    private websocket: WebSocket;

    constructor(@Inject('SecurityController') protected securityController: SecurityController, @Inject('AlertService') protected alertService: AlertService,
        protected logController: LogController) {
    }

    private _started: boolean = false;
    public start() {
        if (this._started)
            return;

        this._started = true;
        //Cada 5 segons comprova l'estat del websocket
        setInterval(this.checkWebSocket, 5000, this);

        this.securityController.loginStatusChanged$.subscribe(value => {
            if (value) {
                this._started = true;
                this.reconnect(this);
            }
            else {
                if (this.websocket != null && this.websocket.OPEN) {
                    this.websocket.close();
                }
                this.websocket = null;
                this._started = false;
            }
        });
    }

    /** Reconnecta amb el socket */
    public reconnect(service: SocketController) {
        if (!this._started)
            return;
        let uri = ws("");
        this.websocket = new WebSocket(uri);
        this.websocket.onopen = (ev: Event) => {
            service.sendCredentials();
            service.reenviaInteresos();
        };
        this.websocket.onclose = (ev: Event) => {
        };
        this.websocket.onerror = (ev: Event) => {
        }
        this.websocket.onmessage = (msg: MessageEvent) => {
            if (service._paused)
                return;
            let notification: SocketNotificationDto = JSON.parse(msg.data);
            if (notification.action === SocketNotificationAction.reloadHome && HDateHour.isGreaterEqualsThan(HDateHour.now(), this.notificationReloadHomeNext)) {
                this.notificationReloadHomeNext = HDateHour.now().addMinutes(this.securityController.MINUTES_BETWEEN_RELOADHOMES);
                service.notificationReloadHome$.next(notification);
            } else if (notification.action === SocketNotificationAction.reloadMenu)
                service.notificationReloadMenu$.next(notification);
            else if (notification.action === SocketNotificationAction.reloadLite)
                service.notificationReloadLite$.next(notification);
            else if (notification.action === SocketNotificationAction.interested)
                service.notificationInterestedOn$.next(notification.interestedOn)
            else if (notification.action === SocketNotificationAction.message) { }
            //service.alertService.alert(service.alertService.getAlertType(notification.messageType), notification.message);
            else if (notification.action === SocketNotificationAction.datasetTimeline)
                service.notificationDatasetTimeline$.next(notification);
            else
                service.notificationReceived$.next(notification);
        };
    }


    private _paused: boolean = false;
    resumeInterest() {
        this._paused = false;
    }
    pauseInterest() {
        this._paused = true;
    }



    private static INTERESOS = new Set<string>();
    public interested(interestedOn: string) {
        if (SocketController.INTERESOS == null)
            SocketController.INTERESOS = new Set<string>();
        if (!SocketController.INTERESOS.has(interestedOn))
            SocketController.INTERESOS.add(interestedOn);
        this.send(SocketNotificationDto.buildInterested(interestedOn));
    }

    reenviaInteresos() {
        if (SocketController.INTERESOS == null)
            SocketController.INTERESOS = new Set<string>();
        SocketController.INTERESOS.forEach(interestedOn => {
            this.send(SocketNotificationDto.buildInterested(interestedOn));
        });
    }
    public sendCredentials() {
        if (HLong.isNullOrNullLong(LogController.USERID))
            this.securityController.getActualUserDto().subscribe((data: userDto) => {
                this.send(SocketNotificationDto.build(SocketNotificationAction.sendingCredentials, LogController.USERID));
            });
        else
            this.send(SocketNotificationDto.build(SocketNotificationAction.sendingCredentials, LogController.USERID));
    }

    public send(object: SocketNotificationDto) {
        this.waitForSocketConnection(this.websocket, function (websocketParam) {
            websocketParam.send(JSON.stringify(object))
        });
    }


    // Make the function wait until the connection is made...
    private waitForSocketConnection(socket: WebSocket, callback) {
        setTimeout(
            function () {
                if (socket && socket.readyState === socket.OPEN) {
                    if (callback != null) {
                        callback(socket);
                    }
                } else if (socket && socket.readyState === socket.CONNECTING) {
                    if (this.waitForSocketConnection)
                        this.waitForSocketConnection(socket, callback);
                }
                else {
                    console.error("Socket is closed")
                }

            }, 50); // wait 5 milisecond for the connection...
    }

    /** Comprova l'estat del socket i si està caigut reconecta */
    public checkWebSocket(service: SocketController): boolean {
        if (!service)
            return;
        let currentstatus: number = service.getStatusId();

        service.status = currentstatus;

        // Si la connexió està tancada la intentem reobrir
        if (currentstatus == undefined || currentstatus == WebSocket.CLOSED)
            service.reconnect(service);
    }


    /**
     * Retorna el estat del websocket en format numèric
     */
    public getStatusId(): number {
        if (!this.websocket)
            return undefined;

        return this.websocket.readyState;
    }

    /**
     * Retorna l'estat del websocket en format texte (CLOSED, CLOSING, OPEN, CONNECTING)
     * @param status estat en format numèric
     */
    public static getStatusString(status: number): string {
        if (status == undefined)
            return "";

        switch (status) {
            case WebSocket.CLOSED:
                return "CLOSED";
            case WebSocket.CLOSING:
                return "CLOSING";
            case WebSocket.OPEN:
                return "OPEN";
            case WebSocket.CONNECTING:
                return "CONNECTING";
            default:
                return "";
        }
    }
}