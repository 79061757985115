import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { SlotsController } from '@shared/src/controllers/address/slots/slots.controller';
import { BookingController } from '@shared/src/controllers/booking/booking.controller';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { HDate } from '@shared/src/datatypes/HDate';
import { HHour } from '@shared/src/datatypes/HHour';
import { SlotActionDto } from '@shared/src/dtos/address/slots/actions/SlotActionDto';
import { BookingDto } from '@shared/src/dtos/booking/BookingDto';
import { OpenResource } from '@shared/src/dtos/open/OpenResource';
import { HDateHour, HTime, HomeController, RouteController } from '@shared/src/public-api';
import { PhaseController } from '../../../controllers/route/phase/phase.controller';
import { HHourInterval } from '../../../datatypes/HHourInterval';
import { AddressTimetableIntervalDto } from '../../../dtos/address/AddressTimetableIntervalDto';
import { iPhaseSlot } from '@shared/src/dtos/phase/iPhaseSlot';
import { ReserveOutOfHoursDto } from '../../../dtos/address/slots/actions/ReserveOutOfHoursDto';

@Component({
    selector: 'shared-slot-picker',
    templateUrl: './shared.slot.picker.component.html',
    styleUrls: ['./shared.slot.picker.component.scss']
})
export class SharedSlotPickerComponent implements OnInit {

    public useIonic: boolean = false;
    constructor(public phaseController: PhaseController,
        public openRouteController: OpenRouteController,
        public routeController: RouteController,
        public slotsController: SlotsController,
        public bookingController: BookingController,
        @Inject('SecurityController') public securityController: SecurityController,
        @Inject('HomeController') public homeController: HomeController) {
        if (homeController.useIonic)
            this.useIonic = homeController.useIonic();
    }

    @Input()
    public hideTitle: boolean = false;

    @Input()
    public booking: BookingDto;

    public _isloadconsole: boolean;
    @Input()
    public set isloadconsole(value: boolean) {
        if (this._isloadconsole == value)
            return;
        this._isloadconsole = value;
        this.calculateGeneralClass();
    }
    public get isloadconsole(): boolean {
        return this._isloadconsole;
    }


    @Input()
    public isActiveRoutes: boolean = false;

    public __phaseId: number;
    @Input()
    public set phaseId(value: number) {
        if (this.__phaseId === value)
            return;
        this.__phaseId = value;
        this.loadData();
    }
    public get phaseId(): number {
        return this.__phaseId;
    }

    private _phase: iPhaseSlot;
    @Input()
    public set phase(value: iPhaseSlot) {
        if (this._phase === value)
            return;
        this._phase = value;
        this.calculateGeneralClass();
        if (value != null)
            this.__phaseId = value.phaseId
        else
            this.__phaseId = 0;

        if (!this._phase.slotId)
            this.loadData();
        else
            this.loadObservacions();
    }
    public get phase(): iPhaseSlot {
        return this._phase;
    }

    public loading: boolean = false;
    public nooptions: boolean = false;
    public showObservacions: Boolean = false;
    public realDate: HDate = null;
    public realInterval: HHourInterval = null;
    public canSlotRelease: Boolean = false;
    public canReserveOutOfHours: Boolean = false;
    public showDespositStartBooking: boolean;
    public showDespositEndBooking: boolean;
    loadData() {
        if (!this.__phaseId || this.loading)
            return;

        this.nooptions = false;
        this.intervals = null;
        this.canSlotRelease = false;
        this.canReserveOutOfHours = false;
        if (this.phase != null && !this.phase.isSlotBlocked) {
            this.loading = true;
            this.phaseController.getIntervalsByPhaseId(this.__phaseId).subscribe(data => {
                this.intervals = data;
                this.loading = false;
                this.showReservarForaHores = false;
                if (!this.canSlotRelease && this.intervals != null && this.intervals.length == 0) {
                    this.nooptions = true;
                }
                this.phaseController.canReserveOutOfHoursByPhaseId(this.__phaseId).subscribe(data => {
                    this.canReserveOutOfHours = data;
                    this.calculateGeneralClass();
                });
            });
            this.phaseController.getCanSlotReleaseByPhaseId(this.__phaseId).subscribe(data => {
                this.canSlotRelease = data;
                if (!this.canSlotRelease && this.intervals != null && this.intervals.length == 0) {
                    this.nooptions = true;
                }
                this.calculateGeneralClass();
            });

        }

        this.loadObservacions();

        if (this.phase) {
            this.bookingController.getBookingByRouteId(this.phase.routeId).subscribe(b => {
                this.booking = b;
                if (this.booking != null) {
                    this.showDespositStartBooking = !HDateHour.isNullOrNullValue(this.booking.depositStart);
                    this.showDespositEndBooking = !HDateHour.isNullOrNullValue(this.booking.depositEnd);
                }
                this.calculateGeneralClass();
            });
        }
    }

    loadObservacions() {
        if (this.phase != null && this.phase.intervalInfo != null && this.phase.intervalInfo.addressTimetableIntervalId != 0) {
            this.showObservacions = SharedSlotPickerComponent.getShowObservacions(this.phase.intervalInfo);
            if (this.showObservacions) {
                this.realDate = SharedSlotPickerComponent.getRealDate(this.phase.intervalInfo);
                this.realInterval = SharedSlotPickerComponent.getRealInterval(this.phase.intervalInfo);
            }
        }
    }

    reload() {
        this.loadData();
    }

    private _intervals: Array<AddressTimetableIntervalDto>
    @Input()
    public set intervals(value: Array<AddressTimetableIntervalDto>) {
        if (this._intervals === value)
            return;
        this._intervals = value;
        this.loadIntervals();
    }
    public get intervals(): Array<AddressTimetableIntervalDto> {
        return this._intervals;
    }

    public addressintervals: AddressTimetableIntervalDto[];

    @Output() public valueChange: EventEmitter<any> = new EventEmitter<any>();

    private _canviSlot: boolean;
    @Input()
    public set canviSlot(value: boolean) {
        if (this._canviSlot === value)
            return;
        this._canviSlot = value;
        this.calculateGeneralClass();
        if (value) {
            this.loadData();
        } else {
            this.showReservarForaHores = false;
        }
    }
    public get canviSlot(): boolean {
        return this._canviSlot;
    }


    ngOnInit(): void {
    }

    public toggleCanviSlot() {
        this.canviSlot = !this.canviSlot;
    }

    loadIntervals() {
        this.addressintervals = [];

        if (!this.intervals)
            return;

        if (this.intervals == null || this.intervals.length <= 0)
            return;

        this.intervals.forEach(element => {
            if (!HHourInterval.isNullOrNullValue(element.interval))
                this.addressintervals.push(element);
        });

        if (this.phase == null || this.phase.intervalInfo == null)
            return;
        if (HHourInterval.isNullOrNullValue(this.phase.intervalInfo.interval))
            return;
        /*
        * Afegeix l'interval reservat, indicat per slotInfo, quan no està present al llistat d'intervals
        * d'una fase retornats pel back.
        * */
        let isInTheList = false;
        for (const addressinterval of this.addressintervals) {
            if (addressinterval.addressTimetableIntervalId === this.phase.intervalInfo.addressTimetableIntervalId) {
                isInTheList = true;
                break
            }
        }
        if (!isInTheList) {
            this.addressintervals.push(this.phase.intervalInfo);
        }

    }

    isSelected(addressinterval: AddressTimetableIntervalDto) {
        return addressinterval != null
            && this.phase != null
            && this.phase.intervalInfo != null
            && this.phase.intervalInfo.addressTimetableIntervalId === addressinterval.addressTimetableIntervalId;
    }

    onIntervalSelected(addressinterval: AddressTimetableIntervalDto) {
        if (this.phase == null || this.phase.phaseId == null)
            return;
        if (addressinterval == null || HHourInterval.isNullOrNullValue(addressinterval.interval))
            return;
        if (this.isSelected(addressinterval))
            return;

        if (!addressinterval.assignable)
            return;

        if (!addressinterval.canSwap && !addressinterval.canObtain) {
            if (addressinterval.addressTimetableIntervalId != null)
                this.openRouteController.phaseAllocateSlot(this.phase.phaseId, addressinterval.addressTimetableIntervalId).subscribe((data: Boolean) => {
                    this.afterLoad(data, false);
                });
            else if (addressinterval.addressDaySettingResourceId != null) {
                this.openRouteController.phaseAllocateSlotByResource(this.phase.phaseId, addressinterval.addressDaySettingResourceId, OpenResource.build(addressinterval)).subscribe((data: Boolean) => {
                    this.afterLoad(data, false);
                });
            }
        } else {
            //Si tenim slot seleccionat, es un swap, sinó, un assign
            if (addressinterval.canSwap) {
                this.slotsController.swapSlots(SlotActionDto.build(this.phase.slotId, this.phase.phaseId, addressinterval.slotIdSwap, addressinterval.phaseIdSwap, this.isActiveRoutes), true).subscribe(data => {
                    this.afterLoad(data, false);
                });
            } else if (addressinterval.canObtain) {
                this.slotsController.obtainSlot(SlotActionDto.build(null, this.phase.phaseId, addressinterval.slotIdSwap, null, this.isActiveRoutes), true).subscribe(data => {
                    this.afterLoad(data, false);
                });
            }
        }
    }

    public clickAlliberarSlot() {
        if (this.phase == null || this.phase.phaseId == null)
            return;

        this.openRouteController.phaseReleaseSlot(this.phase.phaseId, this.isActiveRoutes).subscribe((data: boolean) => {
            this.afterLoad(data, true);
        });
    }

    public showReservarForaHores: boolean = false;
    public toggleReservarForaHores() {
        this.showReservarForaHores = !this.showReservarForaHores;
    }
    public onReservarForaHoresFinish(data: boolean) {
        this.afterLoad(data, false);
    }

    public afterLoad(data: Boolean, canvislot: boolean) {
        if (data) {
            this.canviSlot = canvislot;
        }
        this.reload();
        this.valueChange.next(data);
    }
    public getShowObservacionsFunc = SharedSlotPickerComponent.getShowObservacions;
    public static getShowObservacions(item: AddressTimetableIntervalDto): boolean {
        if (item == null)
            return false;

        return HHour.isGreaterEqualsThan(HHourInterval.getStarts(item.interval), HHour.buildByHoursAndMinutes(24, 0));
    }
    public getRealDateFunc = SharedSlotPickerComponent.getRealDate;
    public static getRealDate(item: AddressTimetableIntervalDto): HDate {
        if (SharedSlotPickerComponent.getShowObservacions(item))
            return HDate.addDaysToHDate(item.onDate, 1);
        else
            return item.onDate;
    }
    public getRealIntervalFunc = SharedSlotPickerComponent.getRealInterval;
    public static getRealInterval(item: AddressTimetableIntervalDto): HHourInterval {
        if (SharedSlotPickerComponent.getShowObservacions(item))
            return HHourInterval.build(HHour.addHours(HHourInterval.getStarts(item.interval), -24), HHour.addHours(HHourInterval.getEnds(item.interval), -24))
        else
            return item.interval;
    }

    /***
     * True si el n-essim element item te una data diferent a l'anterior
     */
    public trencaData(i: number, item: AddressTimetableIntervalDto): boolean {
        if (i == 0)
            return true;
        let prev = this.addressintervals[i - 1];
        return !HDate.equals(item.onDate, prev.onDate);
    }

    public generalClass: string = "";
    public calculateGeneralClass() {
        this.generalClass = "";
        if (this.isloadconsole)
            this.generalClass += " loadconsole";
        if (this.canviSlot)
            this.generalClass += " canviSlot";
        if (this.phase != null && this.phase.isSlotBlocked)
            this.generalClass += " isSlotBlocked";
        if (this.phase != null && this.phase.incompatibleSlot)
            this.generalClass += " incompatibleSlot";
        if (this.phase != null && this.phase.intervalInfo != null && this.phase.intervalInfo.addressTimetableIntervalId != null && this.phase.intervalInfo.addressTimetableIntervalId != 0)
            this.generalClass += " oneSelected";
        if (this.phase != null && this.phase.isBlockedBookSlotSystem)
            this.generalClass += " isBlockedBookSlotSystem";

        console.debug("GeneralClass: " + this.generalClass);
    }
    pretty(value) {
        return JSON.stringify(value);
    }
}
