// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.


const app_backDomain = 'back.test.hardman.app';

const app_Domain = "https://mobile.test.hardman.app";

const apiURI = `https://${app_backDomain}/api`;
const notificationURI = `wss://${app_backDomain}/ws`;

const app_Id = 'dda6a95c';
const app_LiveUpdateChannel = 'Development';

export function api(endpoint: string): string {
  const sep = endpoint != null && !endpoint.startsWith('/') && endpoint.trim() != "" ? '/' : '';
  return `${apiURI}${sep}${endpoint}`;
}
export function ws(endpoint: string): string {
  const sep = endpoint != null && !endpoint.startsWith('/') && endpoint.trim() != "" ? '/' : '';
  return `${notificationURI}${sep}${endpoint}`;
}
export function getHost() { return window.location.host; }

/**
 * Retorna el protocol i host actuals, p.ex: http://localhost:4200
 */
export function getHostWithProtocol() {
  const protocol = window.location.protocol;
  const host = window.location.host;
  return `${protocol}//${host}`;
}

export function getAppHostWithProtocol() { return app_Domain; }

export const environment = {
  production: true,

  /**
   * Host de la app Angular, p.ex: localhost:4200
   */
  BASE_DOMAIN: getHost(),

  /**
   * Host i port de la app Angular, p.ex: http://localhost:4200
   */
  BASE_URI: getHostWithProtocol(),

  /**
   * Host i port base del servidor que te les api, p.ex: localhost:8080
   */
  BASE_API_DOMAIN: app_backDomain,

  /**
   * Host i port base del servidor que te les api, p.ex: http://localhost:8080/api
   */
  BASE_API_URI: apiURI,

  /**
 * Host, port i endpoint de notificaciones, p.ex: ws://localhost:8080/ws
 */
  BASE_NOTIFICATION_API: notificationURI,

  /**
   * Host de l'aplicació mòbil
   */
  BASE_APP_URI: getAppHostWithProtocol(),

  /**
   * Identificador de l'app a Ionic
   */
  APP_ID: app_Id,

  /**
   * Canal d'actualitzacions per Ionic
   */
  APP_LIVE_UPDATE_CHANNEL: app_LiveUpdateChannel,

};