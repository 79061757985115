export enum OpcionsMenu {
    operation = "operation",
    accesscontrol = "accesscontrol",
    accesscontrolsetting = "accesscontrolsetting",
    address = "address",
    addressimporter = "addressimporter",
    addressstrongaddress = "addressstrongaddress",
    ambit = "ambit",
    chat = "chat",
    CustomTable = "CustomTable",
    CustomSetting = "CustomSetting",
    device = "device",
    dropout = "dropout",
    home = "home",
    lite = "lite",
    myposition = "myposition",
    settings = "settings",
    system = "system",
    products = "products",
    tenantcredential = "tenantcredential",
    billing = "billing",
    definitionform = "definitionform",
    formquestion = "formquestion",
    link = "link",
    linkcompany = "linkcompany",
    linkdriver = "linkdriver",
    loadconsole = "loadconsole",
    loadconsolesetting = "loadconsolesetting",
    panelsetting = "panelsetting",
    panels = "panels",
    panel = "panel",
    panelstateroutes = "panelstateroutes",
    internal = "internal",
    test = "test",
    styles = "styles",
    testdatatypes = "testdatatypes",
    testnotificationspush = "testnotificationspush",
    supervision = "supervision",
    activeroutes = "activeroutes",
    activeslots = "activeslots",
    dailyslots = "dailyslots",
    dailydocks = "dailydocks",
    routeimporter = "routeimporter",
    routegroupimporter = "routegroupimporter",
    tenantautomations = "tenantautomations",
    managment = "managment",
    notificationtopicschemasystem = "notificationtopicschemasystem",
    scope = "scope",
    tenantsetting = "tenantsetting",
    tenantinfo = "tenantinfo",
    tenantresource = "tenantresource",
    user = "user",
    activity = "activity",
    partner = "partner",
    partnerall = "partnerall",
    carrier = "carrier",
    freightforwarder = "freightforwarder",
    shipper = "shipper",
    logisticoperator = "logisticoperator",
    finalcustomer = "finalcustomer",
    provider = "provider",
    inspector = "inspector",
    healthcertifier = "healthcertifier",
    partnerimporter = "partnerimporter",
    forcedlink = "forcedlink",
    permissiongroup = "permissiongroup",
    route = "route",
    activeroute = "activeroute",
    routeplanned = "routeplanned",
    routerealized = "routerealized",
    plate = "plate",
    shipment = "shipment",
    statisticsmenu = "statisticsmenu",
    statistics = "statistics",
    statustable = "statustable",
    phasetimestable = "phasetimestable",
    journeytable = "journeytable",
    statisticsdelivery = "statisticsdelivery",
    gate = "gate",
    alarmdefinition = "alarmdefinition",
    clientprovider = "clientprovider",
    tag = "tag",
    managementmodel = "managementmodel",
    documentaryreview = "documentaryreview",
    nonacceptances = "nonacceptances",
    nonacceptanceslist = "nonacceptanceslist",
    nonacceptancescreate = "nonacceptancescreate",
    returns = "returns",
    returnslist = "returnslist",
    returnscreate = "returnscreate",
    inbounds = "inbounds",
    inboundslist = "inboundslist",
    inboundscreate = "inboundscreate",
    outbounds = "outbounds",
    outboundslist = "outboundslist",
    outboundscreate = "outboundscreate",
    delegates = "delegates",
    delegatelist = "delegatelist",
    delegatecreate = "delegatecreate",
    reverselogistics = "reverselogistics",
    reverselogisticslist = "reverselogisticslist",
    reverselogisticscreate = "reverselogisticscreate",
    billtype = "billtype",
    dataandparameters = "dataandparameters",
    screensandforms = "screensandforms",
    securityandnotifications = "securityandnotifications",
    statisticsslotsmenu = "statisticsslotsmenu",
    statisticsslots = "statisticsslots",
    statisticsstaytime = "statisticsstaytime",
    statisticsslotsplanificationbydate = "statisticsslotsplanificationbydate",
    statisticsslotsplanificationbydock = "statisticsslotsplanificationbydock",
    statisticsinternationalplanification = "statisticsinternationalplanification",
    statisticsexternalrouteplanification = "statisticsexternalrouteplanification",
    statisticsinternationalbookingslots = "statisticsinternationalbookingslots",
    statisticsbookingslots = "statisticsbookingslots",
    statisticsbookingslotswithmemory = "statisticsbookingslotswithmemory",
    statisticsslotsbycharacteristics = "statisticsslotsbycharacteristics",
    statisticsslotsbytags = "statisticsslotsbytags",
    statisticsslotsmovements = "statisticsslotsmovements",
    statisticsinternationalmenu = "statisticsinternationalmenu",
    statisticsinternationalcontrol = "statisticsinternationalcontrol",
    statisticsinternationalcontrolcontinuous = "statisticsinternationalcontrolcontinuous",
    statisticsroutesnobooking = "statisticsroutesnobooking",
    statisticsroutesnoslot = "statisticsroutesnoslot",
    statisticsslotskpi = "statisticsslotskpi",
    statisticspartnersmenu = "statisticspartnersmenu",
    statisticsslotsbypartnercarrier = "statisticsslotsbypartnercarrier",
    statisticsslotsbypartnerdriver = "statisticsslotsbypartnerdriver",
    statisticsslotsbypartnerfinalcustomer = "statisticsslotsbypartnerfinalcustomer",
    statisticsslotsbypartnerhearthcertifier = "statisticsslotsbypartnerhearthcertifier",
    statisticsslotsbypartnerinspector = "statisticsslotsbypartnerinspector",
    statisticsslotsbypartnerlogisticoperator = "statisticsslotsbypartnerlogisticoperator",
    statisticsslotsbypartnerprovider = "statisticsslotsbypartnerprovider",
    statisticsslotsbypartnershipper = "statisticsslotsbypartnershipper",
    statisticsplatemenu = "statisticsplatemenu",
    statisticsplatecharacteristics = "statisticsplatecharacteristics",
    telemetryshipment = "telemetryshipment",
    a = "a",
    b = "b",
    c = "c",
    d = "d",
    e = "e",
    booking = "booking",
    bookingroutes = "bookingroutes",
    international = "international",
    typology = "typology",
    bannedphone = "bannedphone",
    userdata = "userdata",
    burguer = "burguer",
    festives = "festives",
    tenanttask = "tenanttask",
    partnerassignmentbyaddress = "partnerassignmentbyaddress",
    deliverycommitment = "deliverycommitment",
    statisticsaccesscontrolbook = "statisticsaccesscontrolbook",
    statisticsaccesscontrollog = "statisticsaccesscontrollog",
    none = "none",
}
