import { Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { FilterStripDto } from '@shared/src/components/core/maintenance/filter/FilterStripDtos';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { MaintenanceSearchByTextDto } from '@shared/src/controllers/maintenance/_base/MaintenanceSearchByTextDtos';
import { AmbitMaintenanceController } from '@shared/src/controllers/maintenance/ambit/AmbitMaintenanceController';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { SocketController } from '@shared/src/controllers/socket/socket.controller';
import { HDateHour } from "@shared/src/datatypes/HDateHour";
import { HLong } from '@shared/src/datatypes/HLong';
import { HString } from '@shared/src/datatypes/HString';
import { ChatsDto } from '@shared/src/dtos/chat/ChatsDto';
import { RouteCardItemDto } from '@shared/src/dtos/route/RouteCardItemDto';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes';
import { RouteFilterTypes } from '@shared/src/enums/RouteFilterTypes';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AddressAmbitSelectorDto } from '../../address/shared.address.ambit.selector.component';
import { SharedAsideFactory } from '../../aside/shared.aside.factory';
import { RouteGroupDto } from '@shared/src/dtos/route/RouteGroupDto';

@Component({
	selector: 'shared-active-slots',
	templateUrl: './shared.active.slots.html',
	styleUrls: ['./shared.active.slots.scss']
})
export class SharedActiveSlots implements OnInit {

	public canCreateInbound: boolean = false;
	public canCreateNonacceptance: boolean = false;
	public canCreateReturn: boolean = false;
	public canCreateOutBound: boolean = false;
	public canCreateDelegate: boolean = false;
	public canCreateReverseLogistics: boolean = false;

	searchTextUpdate = new Subject<string>();

	public static SLICECOUNTINITIAL: number = 100;
	public static SLICECOUNTMORE: number = 100;

	public useIonic: boolean = false;
	constructor(public activatedRoute: ActivatedRoute,
		public router: Router, public location: Location, public routeController: RouteController,
		public ambitController: AmbitMaintenanceController,
		@Inject('HomeController') protected homeController: HomeController,
		@Inject("SecurityController") protected securityController: SecurityController,
		@Inject('SharedAsideFactory') public sharedAsideFactory: SharedAsideFactory,
		protected socketController: SocketController) {

		// Debounce search.
		this.searchTextUpdate.pipe(
			debounceTime(HomeController.DEFAULT_BOUNCE_BUTTON_TIME),
			distinctUntilChanged())
			.subscribe(value => {
				//Abans d'anar a la base de dades, hem de tenir mínim 3 digits o que el searchtext sigui "" (voldrà dir que borra tot per tornar a començar)
				if (HString.isNullOrNullString(this.searchText))
					this.loadData(true, false);
				else if (this.searchText.length > 2)
					this.loadExactKeyData(false);
			});

		this.socketController.notificationReloadHome$.subscribe(e => {
			this.loadData(true, false);
		});

		this.homeController.chatsChanged$.subscribe((data: ChatsDto) => {
			this.setChatInfo(this._routes, data);
		});

		if (homeController.useIonic)
			this.useIonic = homeController.useIonic();

		//Fem subscribe per detectar només el location.back i location.forward que s'executen  només al anar endevant i enrera en els botons del navegador
		//Nomes ho fem amb aquests dos casos ja que o sinó esta constantment detectant canvis al canviar els parametres de url en el routeTo
		this.location.subscribe(
			((value: PopStateEvent) => {
				console.log(value);
				this.loadParametersLocation();
			}),
			(ex => {
				console.log("Error occured postate event")
				console.log(ex);
			})
		);
	}

	public searchText: string = "";

	public showPanelGroupingRoutes: boolean = false;
	public filter: FilterStripDto;// = new FilterStripDto();
	public filterRouteTypes: FilterStripDto;// = new FilterStripDto();
	public filterPeriod: FilterStripDto;// = new FilterStripDto();
	public maintenanceSearchByText: MaintenanceSearchByTextDto = MaintenanceSearchByTextDto.buildPage("", [], 0, 50);
	public routes: Array<RouteCardItemDto> = [];
	public _routes: Array<RouteCardItemDto> = [];
	public routesExactKey: Array<RouteCardItemDto> = [];
	public filterMetadataExactKey: any = {};
	public filterMetadata: any = {};

	ngOnInit(): void {
		if (this.useIonic)
			this.addressAmbit = AddressAmbitSelectorDto.buildActiveSlotsMobile();
		else
			this.addressAmbit = AddressAmbitSelectorDto.buildActiveSlots();

		this.sliceCount = SharedActiveSlots.SLICECOUNTINITIAL;
		this.loadParameters();

		this.router.events.subscribe((event: RouterEvent) => {
			if (event instanceof NavigationEnd) {
				this.loadParametersLocation();
			}
		});
	}

	public allLoaded: boolean = false;
	public static PARAMETERAMBITIDNAME = "ambitId";
	public static PARAMETERADDRESSIDNAME = "addressId";
	public static PARAMETERROUTEIDNAME = "routeId";
	public static PARAMETEROPTIONNAME = "option";
	public static PARAMETERPHASEIDNAME = "phaseId";

	public loadParameters() {

		const routeParams = this.activatedRoute.snapshot.paramMap;
		const queryParams = this.activatedRoute.snapshot.queryParamMap;

		let ambit = routeParams.has(SharedActiveSlots.PARAMETERAMBITIDNAME) ? routeParams.get(SharedActiveSlots.PARAMETERAMBITIDNAME) : queryParams.get(SharedActiveSlots.PARAMETERAMBITIDNAME);
		if (ambit)
			this.ambitId = +ambit;

		let address = routeParams.has(SharedActiveSlots.PARAMETERADDRESSIDNAME) ? routeParams.get(SharedActiveSlots.PARAMETERADDRESSIDNAME) : queryParams.get(SharedActiveSlots.PARAMETERADDRESSIDNAME);
		if (address)
			this.addressId = +address;

		let route = routeParams.has(SharedActiveSlots.PARAMETERROUTEIDNAME) ? routeParams.get(SharedActiveSlots.PARAMETERROUTEIDNAME) : queryParams.get(SharedActiveSlots.PARAMETERROUTEIDNAME);
		if (route)
			this.routeId = +route;
		else
			this.selectedRoute = null;

		this.maintenanceSearchByText.activeFilterKeys = new Array();
		for (var rft in RouteFilterTypes) {
			let rftUrl = routeParams.has(rft) ? routeParams.get(rft) : queryParams.get(rft);
			if (rftUrl != null && rftUrl == "true")
				this.maintenanceSearchByText.activeFilterKeys.push(rft);
		}

		this.allLoaded = true;
	}
	public loadParametersLocation() {

		let path = this.location.path();
		if (path == "")
			return;

		let tree = this.router.parseUrl(path);
		let queryParams = tree.queryParams;

		let ambit = queryParams[SharedActiveSlots.PARAMETERAMBITIDNAME];
		if (ambit)
			this.ambitId = +ambit;

		let address = queryParams[SharedActiveSlots.PARAMETERADDRESSIDNAME];
		if (address)
			this.addressId = +address;

		let route = queryParams[SharedActiveSlots.PARAMETERROUTEIDNAME];
		if (route)
			this.routeId = +route;
		else {
			this.selectedRoute = null;
			this.routeId = 0;
		}

		this.maintenanceSearchByText.activeFilterKeys = new Array();
		for (var rft in RouteFilterTypes) {
			let rftUrl = queryParams[rft];
			if (rftUrl != null && rftUrl == "true")
				this.maintenanceSearchByText.activeFilterKeys.push(rft);
		}

		this.allLoaded = true;
	}

	routeTo() {

		if (!this.allLoaded || this.useIonic)
			return;

		let object = {};

		if (!HLong.isNullOrNullLong(this.routeId))
			object[SharedActiveSlots.PARAMETERROUTEIDNAME] = this.routeId;
		else {
			if (!HLong.isNullOrNullLong(this.ambitId))
				object[SharedActiveSlots.PARAMETERAMBITIDNAME] = this.ambitId;
			if (!HLong.isNullOrNullLong(this.addressId))
				object[SharedActiveSlots.PARAMETERADDRESSIDNAME] = this.addressId;

			if (this.maintenanceSearchByText && this.maintenanceSearchByText.activeFilterKeys) {
				this.maintenanceSearchByText.activeFilterKeys.forEach(element => {
					object[element] = "true";
				});
			}
		}

		if (!HString.startsWith(this.router.url, "/operation/activeslots"))
			return;

		//Aqui no estem rederigint, només estem actualtzant els queryParams perque es vegi reflectida la URL. 
		//Al no redirigir (que ja es el que volem perquè no es necessàri), no està refrescant la pagina i no entrarà pel ngOnInit()
		this.router.navigate([".", {}], { relativeTo: this.activatedRoute, queryParams: object, queryParamsHandling: '' });

	}

	public _routeId: number;
	public get routeId(): number {
		return this._routeId;
	}
	public set routeId(value: number) {
		if (this._routeId === value)
			return;
		this._routeId = value;
	}

	public _addressId: number;
	public get addressId(): number {
		return this._addressId;
	}
	public set addressId(value: number) {
		if (this._addressId === value)
			return;
		this._addressId = value;
		if (this.addressAmbit != null)
			this.addressAmbit.addressId = value;
	}
	public _ambitId: number;
	public get ambitId(): number {
		return this._ambitId;
	}
	public set ambitId(value: number) {
		if (this._ambitId === value)
			return;
		this._ambitId = value;
		if (this.addressAmbit != null)
			this.addressAmbit.ambitId = value;
	}

	public _addressAmbit: AddressAmbitSelectorDto;
	public get addressAmbit(): AddressAmbitSelectorDto {
		return this._addressAmbit;
	}
	public set addressAmbit(value: AddressAmbitSelectorDto) {
		if (this._addressAmbit === value)
			return;
		this._addressAmbit = value;
	}

	ngAfterViewChecked(): void {
	}



	public onChangeAddressAmbitSelector($event: AddressAmbitSelectorDto) {
		this._addressAmbit = $event;
		if ($event != null) {
			this.maintenanceSearchByText.ambitId = $event.ambitId;
			this.maintenanceSearchByText.addressId = $event.addressId;
		} else {
			this.maintenanceSearchByText.ambitId = 0;
			this.maintenanceSearchByText.addressId = 0;
		}

		this._ambitId = this.maintenanceSearchByText.ambitId;
		this._addressId = this.maintenanceSearchByText.addressId;

		this.loadData(true, true);
	}

	loadFilterOfMaintenaceSearchByText() {
		if (this.filter != null)
			this.maintenanceSearchByText.activeFilterKeys = FilterStripDto.getActiveKeys(this.filter);
		if (this.filterRouteTypes != null)
			this.maintenanceSearchByText.activeFilterKeys = this.maintenanceSearchByText.activeFilterKeys.concat(FilterStripDto.getActiveKeys(this.filterRouteTypes));
		if (this.filterPeriod != null)
			this.maintenanceSearchByText.activeFilterKeys = this.maintenanceSearchByText.activeFilterKeys.concat(FilterStripDto.getActiveKeys(this.filterPeriod));
		this.routeTo();
	}

	loadExactKeyData(forcedsearch: boolean) {

		this.loadFilterOfMaintenaceSearchByText();

		if (!HString.isNullOrNullString(this.searchText)) {
			this.routesExactKey = null;
			this.maintenanceSearchByText.searchtext = this.searchText;
			this.routeController.activeslotsExactKey(this.maintenanceSearchByText, false).subscribe(data => {

				if (data == null) {
					this._routes = new Array();
					return;
				}
				this.routesExactKey = data.list;
			});
		}
		else {
			this.routesExactKey = null;
		}
		this.loadData(true, false);
	}

	loadData(forcedsearch: boolean, busy: boolean) {

		//Si tenim ruta seleccionada, no te sentit fer cerca total
		if (this.routeId > 0)
			return;

		this.loadFilterOfMaintenaceSearchByText();

		if (this.maintenanceSearchByText.ambitId == null)
			return;

		this.maintenanceSearchByText.searchtext = this.searchText;
		this.maintenanceSearchByText.idReserca = HDateHour.toStringSorteable(HDateHour.now());
		this.maintenanceSearchByText.sliceCount = this.sliceCount;
		this.routeController.activeslots(this.maintenanceSearchByText, busy).subscribe(data => {

			if (data == null) {
				this._routes = new Array();
				return;
			}

			//Si no es la última petició feta, obiem la resposta
			if (!HString.equals(data.idReserca, this.maintenanceSearchByText.idReserca)) {
				return;
			}
			this._routes = data.list;
			this.setChatInfo(this._routes, this.homeController.CHATS);

			this.routesCount = data.routeAllCount;
			if (this.routes != null) {
				this.itemsCount = data.routeAllCount;

				//comprovem si la seleccionada encara esta
				if (this.selectedRoute != null) {
					let findselected = false;
					let i = 0;
					while (i < this.routes.length) {
						if (this.routes[i].routeId === this.selectedRoute.routeId) {
							findselected = true;
						}
						if (findselected) {
							i = this.routes.length;
						}
						i++;
					}
					//Si la seleccionada ja no està a la llista, la resetejem
					if (!findselected)
						this.selectedRoute = null;
				}
			} else {
				this.selectedRoute = null;
			}

			this.showPanelGroupingRoutes = data.showPanelGroupingRoutes;

			this.filter = FilterStripDto.buildFromFilterStripDto(data.filter);
			this.filterRouteTypes = FilterStripDto.buildFromFilterStripDto(data.filterRouteTypes);
			this.filterPeriod = FilterStripDto.buildFromFilterStripDto(data.filterPeriod);
			this.loadFilterOfMaintenaceSearchByText();

			this.canCreateOutBound = data.canCreateOubound;
			this.canCreateReturn = data.canCreateReturn;
			this.canCreateDelegate = data.canCreateDelegate;
			this.canCreateInbound = data.canCreateInbound;
			this.canCreateNonacceptance = data.canCreateNonacceptance;
			this.canCreateReverseLogistics = data.canCreateReverseLogistics;
		});
	}
	setChatInfo(routes: RouteCardItemDto[], chats: ChatsDto) {
		if (routes) {
			if (chats && chats.chats) {
				chats.chats.forEach(e => {
					if (e.ownerParticipantType == ChatParticipantTypes.Route) {
						if (routes != undefined && this.routes != null) {
							let i = 0;
							while (i < routes.length) {
								if (routes[i].routeId == e.ownerParticipantId) {
									routes[i].pendingMessagesRead = e.pendingMessagesRead;
								}
								i++;
							}
						}
					}
				});
			}
			this.routes = routes;
		}
	}

	public routesCount: number = 0;
	public _selectedRoute: RouteCardItemDto;
	public get selectedRoute(): RouteCardItemDto {
		return this._selectedRoute;
	}
	public set selectedRoute(value: RouteCardItemDto) {
		if (this._selectedRoute === value)
			return;
		this._selectedRoute = value;
		this._routeId = value != null ? value.routeId : 0;
		this.routeTo();
	}

	public itemsCount = 0;
	public sliceCount: number = SharedActiveSlots.SLICECOUNTINITIAL;
	public onSliceMore() {
		this.sliceCount = this.sliceCount + SharedActiveSlots.SLICECOUNTMORE;
		this.loadData(true, false);
	}

	getRoutes() {
		if (this.routes != null) {
			return this.routes;
		}
		return new Array();
	}

	pretty(value) {
		return JSON.stringify(value);
	}


	onSearchFocus(event: any) {
		if (event && event.stopPropagation)
			event.stopPropagation();

	}

	onSearchClick(event: any) {
		if (event && event.stopPropagation)
			event.stopPropagation();
	}


	filterUpdate() {
		this.selectedRoute = null;
		this.loadData(true, true);
	}

	public trackById(index, route) {
		return route.routeId;
	}

	onClickItem(item: RouteCardItemDto) {
		if (item == null) {
			this.selectedRoute = null;
			return;
		}
		this.selectedRoute = item;
	}

	onClickBack(refresh: boolean) {
		this.selectedRoute = null;
		if (refresh || this._routes == null || this._routes.length == 0)
			this.loadData(true, false);
	}

	public displayFlex: boolean = false;
	onClickRouteGroup(event) {
		if (event && event.stopPropagation)
			event.stopPropagation();
		this.displayFlex = true;
	}

	onFinishRouteGroup(value: RouteGroupDto) {
		this.displayFlex = false;
		if (value == null || value.routeGroup == null) {
			return;
		}
		this.selectedRoute = value.routeGroup;
	}

	onClickBackRouteGroup(value: boolean) {
		this.displayFlex = false;
	}

	createNonacceptance(event) {
		if (event && event.stopPropagation)
			event.stopPropagation();

		this.sharedAsideFactory.invokeCreateNonacceptance(null).then(value => {
		});
	}

	createReturn(event) {
		if (event && event.stopPropagation)
			event.stopPropagation();

		this.sharedAsideFactory.invokeCreateReturn(null).then(value => {
		});
	}

	createInbound(event) {
		if (event && event.stopPropagation)
			event.stopPropagation();

		this.sharedAsideFactory.invokeCreateInbound(null).then(value => {
		});
	}

	createOutbound(event) {
		if (event && event.stopPropagation)
			event.stopPropagation();

		this.sharedAsideFactory.invokeCreateOutbound(null).then(value => {
		});
	}

	createDelegate(event) {
		if (event && event.stopPropagation)
			event.stopPropagation();

		this.sharedAsideFactory.invokeCreateDelegate(null).then(value => {
		});
	}

	createReverseLogistics(event) {
		if (event && event.stopPropagation)
			event.stopPropagation();

		this.sharedAsideFactory.invokeCreateReverseLogistics(null).then(value => {
		});
	}
}
