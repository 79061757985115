<div class="container" [ngClass]="{'ionic' : useIonic}">
    <div class="title d-block">{{ 'SHARED.DRIVEREPLATE.COMPONENT.TITLE' | translateH }}</div>
    <ng-container *ngIf="!editMode">
        <div class="info-partners-list">
            <shared-partner-infocard class="driver" [partner]="driver" [type]="'driver'"
                [caption]="'PARTNERS.DRIVERA.CAPTION'">
            </shared-partner-infocard>
            <shared-partner-infocard *ngIf="(showCarrierCard || route.carrierName) && !onlydriver" class="carrier"
                [partner]="carrier" [type]="'carrier'" [caption]="'PARTNERS.CARRIER.CAPTION'">
            </shared-partner-infocard>
        </div>
        <div class="titol">
            <div class="inline">
                <div class="box plateA">
                    <div class="toplabel">{{'ROUTEPAGE.ITEM.EDIT.PLATEA' | translateH}}</div><span class="invisible"
                        *ngIf="!route.plateA">{{'ACTIVESLOTS.NOPLATEA' | translateH}}</span>
                    {{route.plateA}}
                </div>
                <div class="box plateB">
                    <div class="toplabel">{{'ROUTEPAGE.ITEM.EDIT.PLATEB' | translateH}}</div><span class="invisible"
                        *ngIf="!route.plateB">{{'ACTIVESLOTS.NOPLATEB' | translateH}}</span>
                    {{route.plateB}}
                </div>
                <div class="box plateB" *ngIf="!route.hidePlateC">
                    <div class="toplabel">{{'ROUTEPAGE.ITEM.EDIT.PLATEC' | translateH}}</div><span class="invisible"
                        *ngIf="!route.plateC">{{'ACTIVESLOTS.NOPLATEC' | translateH}}</span>
                    {{route.plateC}}
                </div>
            </div>
        </div>
        <button class="link" *ngIf="!readonly"
            (click)="startEditmode()">{{'ROUTEPAGE.ITEM.EDIT.LINK.CHANGE.DRIVERANDPLATES' | translateH}}</button>
    </ng-container>

    <div class="zona-edit" *ngIf="editMode">
        <div class="btn-close" (click)="cancel()">
            <i class="fa fa-close"></i>
        </div>

        <input-component *ngIf="route.showAskCarrierName" [readonly]="readonly" class="col-x" [(ngModel)]="carrierName"
            placeholder="{{'ROUTEPAGE.ITEM.EDIT.CARRIERNAME' | translateH}}"></input-component>
        <input-component *ngIf="route.showAskCarrierLegalIdentification" [readonly]="readonly" class="col-x"
            [(ngModel)]="carrierLegalIdentification"
            placeholder="{{'ROUTEPAGE.ITEM.EDIT.CARRIERLEGALIDENTIFICATION' | translateH}}"></input-component>
        <input-component *ngIf="route.showAskCarrierAddress" [readonly]="readonly" class="col-x" [(ngModel)]="carrierAddress"
            placeholder="{{'ROUTEPAGE.ITEM.EDIT.CARRIERADDRESS' | translateH}}"></input-component>

        <input-component [readonly]="readonly" class="col-x" [(ngModel)]="driverName"
            placeholder="{{'ROUTEPAGE.ITEM.EDIT.DRIVERNAME' | translateH}}"></input-component>
        <input-component [readonly]="readonly" class="col-x" [(ngModel)]="driverLegalIdentification"
            placeholder="{{'ROUTEPAGE.ITEM.EDIT.DRIVERLEGALIDENTIFICATION' | translateH}}"></input-component>
        <input-component [readonly]="readonly" class="col-x" [(ngModel)]="driverPhone"
            placeholder="{{'ROUTEPAGE.ITEM.EDIT.DRIVERPHONE' | translateH}}"></input-component>

        <div class="col-x">
            <input-component class="col-plate" [(ngModel)]="plateA" [readonly]="readonly"
                placeholder="{{'ROUTEPAGE.ITEM.EDIT.PLATEA' | translateH}}"></input-component>
            <input-component class="col-plate" [(ngModel)]="plateB" [readonly]="readonly"
                placeholder="{{'ROUTEPAGE.ITEM.EDIT.PLATEB' | translateH}}"></input-component>
            <input-component *ngIf="!route.hidePlateC" class="col-plate" [(ngModel)]="plateC" [readonly]="readonly"
                placeholder="{{'ROUTEPAGE.ITEM.EDIT.PLATEC' | translateH}}"></input-component>
        </div>

        <boolean-component class="col-x" [(ngModel)]="route.notificationDriver" [readonly]="readonly"
            placeholder="{{'ROUTE.NOTIFICATION.CAPTION' | translateH}}" name="notificationDriver"
            (valueChanged)="changeActiveNotificationDriver()">
        </boolean-component>



        <shared-savebutton (save)="save($event)" [readonly]="readonly">
            {{'ROUTEPAGE.ITEM.EDIT.BUTTON.SAVE' | translateH}}
        </shared-savebutton>
    </div>

</div>