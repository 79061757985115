import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DocumentController } from '@shared/src/controllers/document/document.controller';
import { FileUpDownloadController } from '@shared/src/controllers/fileupdownload/fileupdownload.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { DocumentDetailDto } from '@shared/src/dtos/document/DocumentDetailDto';
import { DocumentDto } from '@shared/src/dtos/document/DocumentDto';
import { Platform } from '@angular/cdk/platform';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { OpenDocumentController } from '@shared/src/controllers/open/open.document.controller';

@Component({
  selector: 'shared-document-view-component',
  templateUrl: 'shared.document.view.component.html',
  styleUrls: ['shared.document.view.component.scss'],
})
export class SharedDocumentViewComponent implements OnInit, OnDestroy {

  public useIonic: boolean = false;
  public isImageType: boolean = false;
  public isPdfType: boolean = false;

  public static NOMINIATURES = "#toolbar=0&view=FitH";

  constructor(@Inject('HomeController') protected homecontroller: HomeController, protected documentController: DocumentController,
    protected openDocumentController: OpenDocumentController,
    private platform: Platform, private file: File, private fileOpener: FileOpener) {
      if (this.platform.ANDROID)
        this.isAndroid = true;
      if (homecontroller.useIonic)
        this.useIonic = homecontroller.useIonic();
  }

  onDownLoad() {
    if (this.documentDetail == null)
      return;
    FileUpDownloadController.resultDownLoadByParameters(this.documentDetail.fileName, this.documentDetail.fileString, this.documentDetail.fileType);
  }

  @Input() public showOpen: boolean;

  private _document: DocumentDto;
  @Input() public set document(value: DocumentDto) {
    if (this._document === value)
      return;

    this._document = value;
    this.loadData();
  }
  public get document(): DocumentDto {
    return this._document;
  }

  private _documentDetail: DocumentDetailDto;
  @Input() public set documentDetail(value: DocumentDetailDto) {
    console.log("SharedDocumentViewComponent.value", value);
    if (this._documentDetail === value)
      return;

    this._documentDetail = value;
    this.loadData();
  }
  public get documentDetail(): DocumentDetailDto {
    return this._documentDetail;
  }

  @Input()
  public nominiatures: boolean = false;

  loadData() {

    if (this.document == null && this.documentDetail == null) {
      this.isImageType = false;
    } else if (this.document) {
      this.formatImages = new Array();
      this.urlDatas = new Array();
      this.loadDocument();
    } else {
      this.formatImages = new Array();
      this.urlDatas = new Array();
      this.loadDocumentDetail();
    }
  }

  public formatImages: string[];
  public urlDatas: string[];
  loadDocument() {

    this.formatImages = new Array();
    this.urlDatas = new Array();

    if (this.document == null)
      return;

    this.isImageType = FileUpDownLoadDto.isImageType(this.document.fileType);
    this.isPdfType = FileUpDownLoadDto.isPdfType(this.document.fileType);

    if (this.document.documentDetailList) {
      let downloadfiletypes = false;
      this.document.documentDetailList.forEach(element => {
        if (element.fileBytes == null) {
          downloadfiletypes = true;
        }
      });
      if (downloadfiletypes) {
        this.documentController.getDocument(this.document.documentId).subscribe(data => {
          this._document = data;
          if (data && data.documentDetailList) {
            this.formatImages = new Array();
            this.urlDatas = new Array();
            data.documentDetailList.forEach(element => {
              this.formatImages.push(this.getDataImageUri(element));
              this.loadUrlData(element);
            });
          }
        });
      } else {
        if (this.document.documentDetailList) {
          this.formatImages = new Array();
          this.urlDatas = new Array();
          this.document.documentDetailList.forEach(element => {
            this.formatImages.push(this.getDataImageUri(element));
            this.loadUrlData(element);
          });
        }
      }
    }
  }

  loadDocumentDetail() {

    this.formatImages = new Array();
    this.urlDatas = new Array();

    if (this.documentDetail == null)
      return;

    this.isImageType = FileUpDownLoadDto.isImageType(this.documentDetail.fileType);
    this.isPdfType = FileUpDownLoadDto.isPdfType(this.documentDetail.fileType);

    if (this.documentDetail.fileBytes == null) {
      this.documentController.getDocumentDetail(this.documentDetail.documentDetailId).subscribe(data => {
        this._documentDetail = data;
        if (this.documentDetail != null) {
          this.formatImages = new Array();
          this.urlDatas = new Array();
          this.formatImages.push(this.getDataImageUri(this.documentDetail));
          this.loadUrlData(this.documentDetail);
        }
      });
    } else {
      this.formatImages = new Array();
      this.urlDatas = new Array();
      this.formatImages.push(this.getDataImageUri(this.documentDetail));
      this.loadUrlData(this.documentDetail);
    }
  }

  getDataImageUri(detail: DocumentDetailDto): string {
    if (detail == null)
      return "";
    return 'data:image/' + detail.fileType + ';base64,' + detail.fileBytes;
  }

  loadUrlData(detail: DocumentDetailDto) {
    if (detail == null)
      return "";

    if (this.isAndroid && this.isPdfType) {
      this.urlDatas.push(this.openDocumentController.getApiDocumentDetailPdfById(this.documentDetail.documentDetailId));
    } else {
      var byteArray = FileUpDownLoadDto.getUint8ArrayFromString(detail.fileString);
      var blob = new Blob([byteArray], { type: FileUpDownLoadDto.getContentType(detail.fileType) });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      fetch(link.href)
        .then(res => res.blob())
        .then(a => {
          if (this.nominiatures && detail.fileType === "pdf") {
            this.urlDatas.push(window.URL.createObjectURL(a) + SharedDocumentViewComponent.NOMINIATURES);
          } else
            this.urlDatas.push(window.URL.createObjectURL(a));

        });
    }
  }

  async openPdf(fileString: string) {

    var byteArray = FileUpDownLoadDto.getUint8ArrayFromString(fileString);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const filePath = this.file.dataDirectory + 'document.pdf';

    // Escribir el Blob en el sistema de archivos
    await this.file.writeFile(this.file.dataDirectory, 'document.pdf', blob, { replace: true });

    // Abrir el archivo PDF
    this.fileOpener.open(filePath, 'application/pdf')
      .then(() => console.log('PDF opened successfully'))
      .catch(err => console.error('Error opening PDF:', err));
  }

  @ViewChild('iframe') iframe: ElementRef;

  ngAfterViewInit() {
    if (this.iframe == null)
      return;
    const nativeEl = this.iframe.nativeElement;
    if ((nativeEl.contentDocument || nativeEl.contentWindow.document).readyState === 'complete') {
      nativeEl.onload = this.onIframeLoad.bind(this);
    } else {
      if (nativeEl.addEventListener) {
        nativeEl.addEventListener('load', this.onIframeLoad.bind(this), true);
      } else if (nativeEl.attachEvent) {
        nativeEl.attachEvent('onload', this.onIframeLoad.bind(this));
      }
    }
  }
  onIframeLoad() {
    if (this.iframe.nativeElement.contentWindow.document.body != null) {
      let iframebodyimg = this.iframe.nativeElement.contentWindow.document.body.children[0];
      if (iframebodyimg != null) {
        iframebodyimg.style.width = '100%';
      }

      let doc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
    }
  }


  public isAndroid = false;
  ngOnInit() {
      if (this.platform.ANDROID)
          this.isAndroid = true;
  }

  ngOnDestroy() {
  }

  pretty(value: any): string {
    return JSON.stringify(value);
  }

}
