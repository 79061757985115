import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HTags } from '@shared/src/datatypes/HTags';
import { HTriState } from '@shared/src/datatypes/HTriState';
import { SlotInfoDto } from '@shared/src/dtos/address/slots/SlotInfoDto';
import { StatusTransitionsDto } from '@shared/src/dtos/generalstatus/StatusTransitionsDto';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { ChangeBlameDto } from '@shared/src/dtos/phase/ChangeBlameDto';
import { PhaseDeliveryStateDto } from '@shared/src/dtos/phase/PhaseDeliveryStateDto';
import { PhaseDto } from '@shared/src/dtos/phase/PhaseDto';
import { PhaseItemMaintenanceCtrlDto } from '@shared/src/dtos/phase/PhaseItemMaintenanceCtrlDto';
import { PhaseLogDto } from '@shared/src/dtos/phase/PhaseLogDto';
import { PhaseProcessDataDto } from '@shared/src/dtos/phase/PhaseProcessDataDto';
import { PhaseShipmentProcessDataDto } from '@shared/src/dtos/phase/PhaseShipmentProcessDataDto';
import { PhaseTimesExplainedDto } from '@shared/src/dtos/phase/PhaseTimesExplainedDto';
import { ShipmentsTransferDto } from '@shared/src/dtos/shipment/ShipmentsTransferDto';
import { Blames, HString, HTime } from '@shared/src/public-api';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { Observable } from 'rxjs';
import { AddressTimetableIntervalDto } from '../../../dtos/address/AddressTimetableIntervalDto';
import { PhaseCardDto } from '../../../dtos/phase/PhaseCardDto';
import { BaseController } from '../../base.controller';
import { PhaseItemMaintenanceDto } from '../../maintenance/phase/PhaseItemMaintenanceDto';
import { SecurityController } from '../../security/security.controller';
import { PhaseChangeDatesDto } from '@shared/src/dtos/phase/PhaseChangeDatesDto';
import { PhaseStatusTimeDto } from '../dto/PhaseStatusTimeDto';
import { CompatibleDocksDto } from '@shared/src/dtos/phase/CompatibleDocksDto';
import { ReserveOutOfHoursDto } from '@shared/src/dtos/address/slots/actions/ReserveOutOfHoursDto';
import { PhaseSecretDto } from './PhaseSecretDto';
import { RoutePartCMRSignatureAgents } from '../cmr/RoutePartCMRSignatureAgents';


@Injectable({
    providedIn: 'root',
})
export class PhaseController extends BaseController {
    private base = '/phase'

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router
    ) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getPhasesByRouteId(routeId: number): Observable<Array<PhaseDto>> {
        return this.internal_get(this.base + "/byroute/" + routeId)
    }

    public getPhase(phaseId: number): Observable<PhaseCardDto> {
        if (phaseId == null)
            return null;
        return this.internal_get(this.base + "/card/" + phaseId)
    }

    public getPhaseItemMaintenanceCtrl(phaseId: number): Observable<PhaseItemMaintenanceCtrlDto> {
        return this.post(this.base + "/maintenance", phaseId, "getPhaseItemMaintenance");
    }

    public getIntervalsByPhaseId(phaseId: number): Observable<Array<AddressTimetableIntervalDto>> {
        return this.get(this.base + "/" + phaseId + "/intervals");
    }

    public getCanSlotReleaseByPhaseId(phaseId: number): Observable<Boolean> {
        return this.internal_get(this.base + "/" + phaseId + "/canslotrelease");
    }

    public canReserveOutOfHoursByPhaseId(phaseId: number): Observable<Boolean> {
        return this.internal_get(this.base + "/" + phaseId + "/canreserveoutofhours");
    }

    public getDefaultDataReserveOutOfHoursByPhaseId(phaseId: number): Observable<ReserveOutOfHoursDto> {
        return this.internal_get(this.base + "/" + phaseId + "/getdefaultdatareserveoutofhours");
    }

    public getByPhaseId(phaseId: number): Observable<PhaseDto> {
        return this.internal_get(this.base + "/" + phaseId);
    }

    public getPhaseStates(phaseId: number): Observable<StatusTransitionsDto> {
        return this.get(this.base + "/states/" + phaseId);
    }
    public getSlotInfo(phaseId: number): Observable<SlotInfoDto> {
        return this.get(this.base + "/slotinfo/" + phaseId);
    }
    public getLostSlotInfo(phaseId: number): Observable<SlotInfoDto> {
        return this.get(this.base + "/lostslotinfo/" + phaseId);
    }


    public calculatePhaseBlocked(phaseId: number): Observable<Boolean> {
        return this.post(this.base + "/calculatephaseblocked", { 'phaseId': phaseId }, "calculateblockphasebysystem");
    }
    public blockPhaseByUser(phaseId: number, value: HTriState): Observable<Boolean> {
        return this.post(this.base + "/blockphasebyuser", { 'phaseId': phaseId, 'value': value }, "blockPhaseByUser");
    }
    public calculatePhaseBlockedExternals(phaseId: number): Observable<Boolean> {
        return this.post(this.base + "/calculatephaseblockedexternals", { 'phaseId': phaseId }, "calculateblockphasebysystem");
    }
    public blockExternalsPhaseByUser(phaseId: number, value: HTriState): Observable<Boolean> {
        return this.post(this.base + "/blockexternalsphasebyuser", { 'phaseId': phaseId, 'value': value }, "blockPhaseByUser");
    }

    public getPhaseDeliveryStates(phaseId: number): Observable<PhaseDeliveryStateDto> {
        return this.get(this.base + "/deliverystate/" + phaseId);
    }

    public addPhase(phase: PhaseItemMaintenanceDto): Observable<PhaseItemMaintenanceDto> {
        return this.post(this.base + "/add", phase, "addPhase");
    }

    public clonePhase(phaseId: number): Observable<PhaseItemMaintenanceDto> {
        return this.post(this.base + "/clone/" + phaseId, {}, "clonePhase");
    }

    public deletePhase(phaseId: number): Observable<boolean> {
        return this.delete(this.base + "/delete/" + phaseId)
    }

    public pujarPhase(phaseId: number): Observable<boolean> {
        return this.getBusy(this.base + "/pujar/" + phaseId);
    }

    public baixarPhase(phaseId: number): Observable<boolean> {
        return this.getBusy(this.base + "/baixar/" + phaseId);
    }

    public reorderPhases(phases: Array<PhaseCardDto>): Observable<boolean> {
        return this.postBusy(this.base + "/reorder", phases, "reorderPhases");
    }

    public transferShipments(request: ShipmentsTransferDto): Observable<boolean> {
        return this.postBusy(this.base + "/transfer-shipments", request, "transferShipments");
    }

    public getPhaseTimes(phaseId: number, blame: Blames): Observable<PhaseTimesExplainedDto> {
        return this.get(this.base + "/times/" + phaseId + "/" + blame);
    }

    public getAvailablePhasesToGo(phaseId: number): Observable<Array<PhaseDto>> {
        return this.get(this.base + "/available-phases-to-go/" + phaseId);
    }

    public setFromPhaseToPhase(currentPhaseId: number, toPhaseId: number): Observable<boolean> {
        return this.get(this.base + "/set-from/" + currentPhaseId + "/to/" + toPhaseId);
    }

    public setPhaseBlame(phaseId: number, data: ChangeBlameDto): Observable<boolean> {
        return this.postBusy(this.base + "/times/" + phaseId + "/set-blame", data, "setPhaseBlame");
    }
    public setForcedStrongAddress(phaseId: number, addressId: number): Observable<boolean> {
        return this.postBusy(this.base + "/forcedstrongaddress/" + phaseId, addressId, "setForcedStrongAddress");
    }
    public changeDates(phaseId: number, data: PhaseChangeDatesDto): Observable<boolean> {
        return this.postBusy(this.base + "/changedates/" + phaseId, data, "changeDates");
    }
    public setManualTags(phaseId: number, manualTags: HTags) {
        return this.postBusy(this.base + "/set/phaseid/" + phaseId + "/manualtags", manualTags, "setManualTagsPhaseId");
    }
    public setTags(phaseId: number, userTags: HTags, privateTags: HTags, manualTags: HTags) {
        return this.postBusy(this.base + "/set/phaseid/" + phaseId + "/tags", { 'userTags': userTags, 'privateTags': privateTags, 'manualTags': manualTags }, "setManualTagsPhaseId");
    }
    public getSlotPhasesByRoute(routeId: number): Observable<Array<PhaseCardDto>> {
        return this.get(this.base + "/slot-phases-by-route/" + routeId);
    }
    public generateVerificationCode(phaseId: number): Observable<string> {
        return this.put(this.base + "/code/generate/" + phaseId, null, "generateVerificationCode");
    }

    public setCompatibleDocks(phaseId: number, data: CompatibleDocksDto): Observable<boolean> {
        return this.putBusy(this.base + "/compatibledocks/" + phaseId, data, "setCompatibleDocks");
    }

    ////////////////////////////// Endpoints Mobile //////////////////////////////

    public getPhaseCardMini(phaseId: number): Observable<PhaseCardDto> {
        return this.get(this.base + "/mobile/cardmini/" + phaseId)
    }
    public getPhaseProcessData(phaseId: number): Observable<PhaseProcessDataDto> {
        return this.get(this.base + "/mobile/phaseprocessdata/" + phaseId)
    }
    public getPhaseShipmentProcessData(phaseId: number): Observable<PhaseShipmentProcessDataDto> {
        return this.get(this.base + "/mobile/phaseshipmentprocessdata/" + phaseId)
    }
    public phaseCalculateETA(phaseId: number): Observable<boolean> {
        return this.get(this.base + "/mobile/calculateeta/" + phaseId)
    }
    public getPhaseLogs(phaseId: number): Observable<Array<PhaseLogDto>> {
        return this.get(this.base + "/mobile/logs/" + phaseId);
    }
    public getActivePhaseByRouteId(routeId: number): Observable<PhaseCardDto> {
        return this.get(this.base + "/mobile/activeroute/" + routeId + "/activephase")
    }
    public getActivePhaseIdByRouteId(routeId: number): Observable<number> {
        return this.get(this.base + "/mobile/activeroute/" + routeId + "/activephaseid")
    }

    public getPhaseStatesMobile(phaseId: number): Observable<StatusTransitionsDto> {
        return this.get(this.base + "/mobile/states/" + phaseId);
    }

    public executeAdditionalActionMobile(routeid: number, phaseid: number, action: string): Observable<Boolean> {
        return this.post(this.base + "/mobile/additionalaction/execute/" + routeid + "/" + phaseid, action, "executeAdditionalActionMobile");
    }

    executePhaseActions(action: ActionDto, phase: PhaseCardDto): Observable<any> {
        if (phase != null)
            if (action != null) {
                if (action.actionId == "PHASE.BLOCKSLOT.CALCULATE.SYSTEM")
                    return this.calculatePhaseBlocked(phase.phaseId);
                if (action.actionId == "PHASE.BLOCKSLOT.TRUE")
                    return this.blockPhaseByUser(phase.phaseId, HTriState.build(HTriState.True));
                if (action.actionId == "PHASE.BLOCKSLOT.FALSE")
                    return this.blockPhaseByUser(phase.phaseId, HTriState.build(HTriState.False));
                if (action.actionId == "PHASE.BLOCKSLOT.UNSET")
                    return this.blockPhaseByUser(phase.phaseId, HTriState.build(HTriState.Indeterminate));
                if (action.actionId == "PHASE.BLOCKEXTERNALSSLOT.CALCULATE.SYSTEM")
                    return this.calculatePhaseBlockedExternals(phase.phaseId);
                if (action.actionId == "PHASE.BLOCKEXTERNALSSLOT.TRUE")
                    return this.blockExternalsPhaseByUser(phase.phaseId, HTriState.build(HTriState.True));
                if (action.actionId == "PHASE.BLOCKEXTERNALSSLOT.FALSE")
                    return this.blockExternalsPhaseByUser(phase.phaseId, HTriState.build(HTriState.False));
                if (action.actionId == "PHASE.BLOCKEXTERNALSSLOT.UNSET")
                    return this.blockExternalsPhaseByUser(phase.phaseId, HTriState.build(HTriState.Indeterminate));
                if (action.actionId == "PHASE.VERIFICATIONCODE.GENERATE")
                    return this.generateVerificationCode(phase.phaseId);

                if (HString.startsWith(action.actionId, "PHASECUSTOMACTION"))
                    return this.executeAction(phase.phaseId, action.actionId);
            }

        return new Observable<any>((observer) => {
            observer.next(false);
            observer.complete();
        });
    }

    public executeAction(phaseId: number, actionId: string): Observable<Boolean> {
        return this.post(this.base + "/executeaction/" + phaseId + "/" + actionId, { 'phaseId': phaseId, 'actionId': actionId }, "executeAction." + actionId);
    }

    public getStatusTimeById(id: any): Observable<PhaseStatusTimeDto> {
        return this.get(this.base + "/statustime/phaseid/" + id);
    }

    public getPhaseSecretInfo(phaseId: number): Observable<PhaseSecretDto> {
        return this.getNoCancel(this.base + "/secretinfo/phaseid/" + phaseId);
    }

    public getSignUUID(phaseId: number): Observable<string> {
        return this.get(this.base + "/signuuid/phaseid/" + phaseId);
    }
    public getSignUUIDURI(phaseId: number, agent: RoutePartCMRSignatureAgents): Observable<string> {
        return this.get(this.base + "/signuuid/uri/phaseid/" + phaseId + "/agent/" + agent);
    }


    //////////////////////////////////////////////////////////////////////////////////////////
}
